/**
 * <EmojiIcon name="coffee" animated />
    <EmojiIcon name="smile" animated />
    <EmojiIcon name="wonder" animated />
    <EmojiIcon name="leadership" animated />
    <EmojiIcon name="clap" animated />
    <EmojiIcon name="businessDev" animated />
    <EmojiIcon name="flagUk" animated />
    <EmojiIcon name="flagNo" animated />
    <EmojiIcon name="important" animated />
    <EmojiIcon name="livestream" animated />
    <EmojiIcon name="party" animated />
    <EmojiIcon name="workshop" animated />
    <EmojiIcon name="sustainability" animated />
    <EmojiIcon name="stage" animated />
    <EmojiIcon name="question" animated />
    <EmojiIcon name="presentation" animated />
    <EmojiIcon name="content" animated />
    <EmojiIcon name="design" animated />
    <EmojiIcon name="tech" animated />
    <EmojiIcon name="productService" animated />
 * 
*/

import React from "react";
// import classNames from "classnames";

// import Emoji from "a11y-react-emoji";
import EmojiIcon from "../primitives/EmojiIcon";

// Primitives
import WrapRotation from "../primitives/WrapRotation";
import WrapMouseMove from "../primitives/WrapMouseMove";
import WrapLink from "../primitives/WrapLink";
// import WrapArrow from "../primitives/WrapArrow";

const HomePoster = () => {
  return (
    <header
      className="
        relative
        w-full
        pb-8
        mb-16
        uppercase whitespace-nowrap
      "
      // NetlifeSansYSymbols
      id="home-poster"
    >
      <div
        className="
          grid
          grid-cols-12
          gap-x-8
          gap-y-4
          pt-8 pb-4
        "
      >
        {/* Removed rotation for 2024 */}
        {/* Green background */}
        {/* <WrapRotation className="absolute inset-0" introZoomBack> */}

        <div className="absolute inset-0 bg-black" />
        {/* </WrapRotation> */}

        {/* 1st link */}
        {/* <div className="col-span-10 flex justify-center">
          <WrapRotation className="" introDelay={0.2} introDirection="fromLeft">
            <WrapMouseMove>
              <WrapLink className="text-2xl cursor	" href="/">
                See you in October
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div> */}

        {/* 4th link */}
        {/* <div className="col-span-12 md:col-span-5 md:col-start-2 lg:col-start-7 flex justify-center">
          <WrapRotation className="" introDelay={0.5}>
            <WrapMouseMove>
              <WrapLink className="text-xl cursor	" href="/">
                Tickets on Sale March 1st
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div> */}

        {/* 1st link */}
        <div className="col-span-12 md:col-span-4 md:col-start-4 lg:col-start-5 flex justify-center">
          <WrapRotation className="" introDelay={0.2} introDirection="fromLeft">
            <WrapMouseMove>
              <WrapLink color="white" className="text-2xl" href="/tickets">
                Design <EmojiIcon name="design" animatedHover />
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div>
        {/* 4th link */}
        <div className="col-span-12 md:col-span-4 md:col-start-4 lg:col-start-5 flex justify-center">
          <WrapRotation className="" introDelay={0.5}>
            <WrapMouseMove>
              <WrapLink color="white" className="text-2xl" href="/tickets">
                Technology <EmojiIcon name="tech" animatedHover />
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div>

        {/* 3rd link */}
        <div className="col-span-12 md:col-span-4 md:col-start-4 lg:col-start-5 flex justify-center">
          <WrapRotation className="" introDelay={0.4}>
            <WrapMouseMove>
              <WrapLink color="white" className="text-2xl" href="/tickets">
                <EmojiIcon name="productService" animatedHover /> Product
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div>

        {/* 2nd link */}
        <div className="col-span-12 md:col-span-4 md:col-start-4 lg:col-start-5 flex justify-center">
          <WrapRotation className="" introDelay={0.3}>
            <WrapMouseMove>
              <WrapLink color="green" className="text-2xl" href="/tickets">
                <EmojiIcon name="stage" animatedHover /> Get tickets here{" "}
                <EmojiIcon name="language" animatedHover />
              </WrapLink>
            </WrapMouseMove>
          </WrapRotation>
        </div>
      </div>
    </header>
  );
};

export default HomePoster;
